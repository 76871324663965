import React from "react"
import Layout from "../components/layout"
import SEOHeader from "../components/seo-header"
import "../styles/style.scss"

export default function GetTheApp() {
  return (
    <Layout>
      <SEOHeader
        title="Get the GoFloaters App"
        description="GoFloaters is a platform that helps you find work and meeting spaces that can be booked instantly."
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
      ></SEOHeader>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <img
              src="https://assets.gofloaters.com/homepage/check02.gif"
              style={{ width: "400px" }}
            ></img>
            <h1 className="text-center" style={{ paddingTop: "40px" }}>
              <b style={{ color: "#4f9fc8" }}>GoFloaters WorqFlexi App</b>
              <br />
              Successfully Installed
            </h1>
            <br />
            <a href="https://slack.com/" target="_blank">
              Open Slack
            </a>
            <br />
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
